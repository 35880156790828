import React from "react"
import fonts from "../../../styles/fonts"
import colors from "../../../styles/colors"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import styled from "styled-components"

const TextBlockContainer = styled.div`
  ${fonts.sofiaPro};
  color: ${colors.charcoal};
  font-size: 16px;
  line-height: 1.33;

  p {
    margin-bottom: 20px;
    :last-of-type {
      margin-bottom: 0;
    }
  }

  b {
    ${fonts.sofiaProBold};
  }

  a {
    color: inherit;
  }

  i {
    font-style: italic;
  }
`
const TextBlock = (props) => {
  const { wysiwyg } = props
  return <TextBlockContainer>{renderRichText(wysiwyg)}</TextBlockContainer>
}

export default TextBlock
