import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import ContentContainer from "../Core/Containers/ContentContainer"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const TitleTextBlockContainer = styled.div`
  text-align: center;
  background: ${colors.putty};
  padding: 80px 0;

  @media (min-width: ${breakpoints.sm}) {
    padding: 80px 30px 80px;
  }
  @media (min-width: ${breakpoints.md}) {
    padding: 80px 30px 80px;
  }
`

const BlockContent = styled.div`
  max-width: 720px;
  margin: auto;
`

const Title = styled.div`
  ${fonts.garamondRegular};
  color: ${colors.charcoal};
  font-size: 32px;
  line-height: 1.15;
  margin-bottom: 30px;

  @media (min-width: ${breakpoints.md}) {
    font-size: 40px;
  }
`

const Content = styled.div`
  ${fonts.sofiaPro};
  color: ${colors.charcoal};
  font-size: 16px;
  line-height: 1.33;

  p {
    margin-bottom: 20px;
    :last-of-type {
      margin-bottom: 0;
    }
  }

  i {
    font-style: italic;
  }

  b {
    ${fonts.sofiaProBold};
  }

  a {
    color: ${colors.charcoal};
  }
`

const TitleTextBlock = (props) => {
  const { title, content } = props
  return (
    <TitleTextBlockContainer>
      <ContentContainer>
        <BlockContent>
          <Title>{title}</Title>
          <Content>{renderRichText(content)}</Content>
        </BlockContent>
      </ContentContainer>
    </TitleTextBlockContainer>
  )
}

export default TitleTextBlock
