import React from 'react'
import styled from "styled-components"

const StyledContainer = styled.div`
  padding: 30px 30px;

`

const ContentContainer = ({children}) => {
  return (
    <StyledContainer>
      {children}
    </StyledContainer>
  )
}

export default ContentContainer

