import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import PageContents from "../components/About/PageContents"

const AboutPage = ({ location, data }) => {

  const path = location.pathname
  const { page } = data

  const { metaTitle, metaDescription, socialMediaImage, blocks } = page

  let sharingImage = false

  if (socialMediaImage && socialMediaImage.file) {
    sharingImage = socialMediaImage.file.url
  }

  return (
    <Layout transparentHeader={true}>
      <Seo
        path={path}
        title={metaTitle}
        description={metaDescription}
        fullTitle={false}
        image={sharingImage}
      />
      <PageContents blocks={blocks} />
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPageQuery {
    page: contentfulPage(slug: { eq: "about" }) {
      id
      metaTitle
      metaDescription
      socialMediaImage {
        file {
          url
        }
      }
      blocks {
        ...heroFragment
        ...titleTextFragment
        ...textImageFragment
        ...bodyBlockFragment
        ...quizCalloutFragment
      }
    }
  }
`
