import React from 'react'
import breakpoints from "../../../styles/breakpoints"
import fonts from "../../../styles/fonts"
import colors from "../../../styles/colors"
import ContentContainer from "../../Core/Containers/ContentContainer"
import styled from 'styled-components'
import OutlineCard from './OutlineCard'
import QuoteBlock from './QuoteBlock'
import TextBlock from './TextBlock'

const BodyBlockContainer = styled.div`
  margin-bottom: ${props => props.last? "0" : "80px"};

  @media (min-width: ${breakpoints.sm}) {
    padding: 0 30px;
  }
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: ${props => props.last? "0" : "80px"};
  }
`

const Title = styled.h2`
  ${fonts.garamondRegular};
  color: ${colors.charcoal};
  font-size: 32px;
  line-height: 1.15;
  text-align: center;
  margin: auto;
  margin-bottom: ${props => props.bodyCenter? "10px" : "30px"};
  max-width: 720px;

  @media (min-width: ${breakpoints.md}) {
    font-size: 40px;
    margin-bottom: ${props => props.bodyCenter? "20px" : "30px"};
  }
`

const Body = styled.div`
  text-align: ${props => props.center ? "center" : "left"};
  max-width: 720px;
  margin: auto;
`

const BodyBlock = (props) => {
  const { title, last, body } = props
  let textCentered = false;

  body.find((bodyBlock) => {
    const {__typename: type} = bodyBlock;
    if (type === "ContentfulTextImageBlock") {
      textCentered = true;
    }
  })

  const renderBody = (bodyBlock) => {
    const {__typename: type} = bodyBlock;

    if (type === "ContentfulTextImageBlock") {
      return <OutlineCard {...bodyBlock} />
    }
    if (type === "ContentfulPullQuote") {
      return <QuoteBlock {...bodyBlock} />
    }
    if (type === "ContentfulWysiwyg") {
      return <TextBlock {...bodyBlock} />
    }
  }

  return (
    <BodyBlockContainer last={last ? 1 : 0}>
      <ContentContainer>
        <Title bodyCenter={textCentered ? 1 : 0}>{title}</Title>
        <Body center={textCentered ? 1 : 0}>
          {body.map((body, index) => {
            return (
              <React.Fragment key={index}>
                {renderBody(body)}
              </React.Fragment>
            )
          })}
        </Body>
      </ContentContainer>
    </BodyBlockContainer>
  )
}

export default BodyBlock
