import React from "react"
import styled from "styled-components"
import breakpoints from "../../../styles/breakpoints"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const MobileImage = styled(GatsbyImage)`
  display: block !important;
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    display: none !important;
  }
`

const DesktopImage = styled(GatsbyImage)`
  display: none !important;
  opacity: 1 !important;
  transition: opacity 20s !important;
  width: 100%;


  @media (min-width: ${breakpoints.md}) {
    display: block !important;
  }
`

const ResponsiveImage = ({ imageMobile, imageDesktop, alt, loading = "lazy"}) => {

  return (
    <>
      <MobileImage
        image={imageMobile ? getImage(imageMobile) : getImage(imageDesktop)}
        loading={loading}
        alt={alt || ""}
      />
      <DesktopImage
        image={getImage(imageDesktop)}
        loading={loading}
        alt={alt || ""}
      />
    </>
  )
}

export default ResponsiveImage
