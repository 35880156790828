import React from "react"
import styled from "styled-components"
import BodyBlock from "./body/BodyBlock"
import colors from "../../styles/colors"

const GradientBackground = styled.div`
  background: ${colors.putty};
  padding-top: 80px;
  > div:nth-child(2){
    background-color: ${colors.vanilla};
    padding: 80px 0 80px;
  }
`

const GradientBlock = (props) => {
  const { blocks } = props
  return (
    <GradientBackground>
      {blocks.map((block, index) => {
        const isLast = index === blocks.length - 1
        return <BodyBlock {...block} key={index} last={isLast} />
      })}
    </GradientBackground>
  )
}

export default GradientBlock
